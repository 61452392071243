import React from "react"
import Img from "gatsby-image"
import "../styles/components/_newsItem.scss"

const NewsItem = ({ item, num }) => {
  const itemClass = num < 5 ? "news-item active" : "news-item"

  return (
    <li className={itemClass}>
      <a href={item.url} target="_blank" rel="noopener noreferrer">
        <Img
          fluid={item.source.logo.fluid}
          alt={item.source.logo.description}
        />
        <ul className="news-text">
          <li className="brand">
            <img
              src={item.brand.brandLogo.file.url}
              alt={item.brand.brandLogo.description}
            />
          </li>
          <li className="title">{item.title}</li>
          <li className="published">{`${item.publishDate} - ${item.source.name}`}</li>
        </ul>
      </a>
    </li>
  )
}

export default NewsItem
