import React from "react"
import { graphql } from "gatsby"
import handleScroll from "../functions/handleScroll"
import Header from "../components/header"
import Meta from "../components/meta"
import Footer from "../components/footer"
import TitleHeader from "../components/titleHeader"
import ListSelectors from "../components/listSelectors"
import NewsList from "../components/newsList"
import PressList from "../components/pressList"
import InquiryWidget from "../components/inquiryWidget"
import "../styles/pages/_press.scss"

export default class PressPage extends React.Component {
  getInitialState = () => {
    if (typeof window !== "undefined") {
      return sessionStorage.getItem("currentSelector") ?? "news-results"
    }

    return "news-results"
  }

  state = {
    currentSelector: this.getInitialState(),
  }

  setCurrentSelector = selector => this.setState({ currentSelector: selector })

  componentDidMount() {
    window.addEventListener("scroll", this.scrollActions)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollActions)
  }

  scrollActions = e => {
    handleScroll(e)

    const target = e.target
    const activeList = e.target
      .getElementById("press-page")
      .classList.contains("news-results")
      ? target.getElementById("news-list")
      : target.getElementById("press-list")
    const scrollPosition = target.scrollingElement.scrollTop
    const windowBottom = scrollPosition + window.innerHeight
    const listBottom =
      activeList.getBoundingClientRect().top +
      activeList.offsetHeight +
      window.pageYOffset
    const activeElems = activeList.getElementsByClassName("active")
    const lastActive = activeElems[activeElems.length - 1]

    if (lastActive.nextSibling && windowBottom > listBottom) {
      lastActive.nextSibling.classList.add("active")
    }
  }

  render() {
    const pressPageData = this.props.data.allContentfulPressPage.nodes[0]
    const pressItemsData = this.props.data.allContentfulPressItem.nodes
    const newsItemsData = this.props.data.allContentfulNewsItem.nodes

    return (
      <div
        id="press-page"
        className={`press-page ${this.state.currentSelector}`}
      >
        <Meta
          title={pressPageData.seo.title}
          description={pressPageData.seo.description.description}
          image={pressPageData.seo.socialImage.file.url}
          url={this.props.location.href}
          imgAlt={pressPageData.seo.socialImage.description}
        />
        <Header />
        <TitleHeader title={pressPageData.title} />
        <InquiryWidget
          title={pressPageData.inquiryWidget.inquiryTitle}
          contactName={pressPageData.inquiryWidget.contactName}
          email={pressPageData.inquiryWidget.email}
          socialTitle={pressPageData.inquiryWidget.socialTitle}
          social={pressPageData.inquiryWidget.socialMedia}
        />
        <ListSelectors
          data={["Recent News", "Press Releases"]}
          setCurrentSelector={this.setCurrentSelector}
        />
        <div className="content">
          <div className="pointers">
            <div className="pointer news-pointer"></div>
            <div className="pointer press-pointer"></div>
          </div>
          <div className="list-wrapper">
            <NewsList newsItems={newsItemsData} />
            <PressList pressItems={pressItemsData} />
          </div>
          <InquiryWidget
            title={pressPageData.inquiryWidget.inquiryTitle}
            contactName={pressPageData.inquiryWidget.contactName}
            email={pressPageData.inquiryWidget.email}
            socialTitle={pressPageData.inquiryWidget.socialTitle}
            social={pressPageData.inquiryWidget.socialMedia}
          />
        </div>
        <Footer />
      </div>
    )
  }
}

export const query = graphql`
  query pressPageQuery {
    allContentfulNewsItem(sort: { order: DESC, fields: publishDate }) {
      nodes {
        brand {
          brandLogo {
            description
            file {
              url
            }
          }
        }
        publishDate(formatString: "MMMM D, YYYY")
        source {
          logo {
            description
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          name
        }
        title
        url
      }
    }
    allContentfulPressItem(sort: { order: DESC, fields: publishedDate }) {
      nodes {
        id
        brand {
          brandLogo {
            description
            file {
              url
            }
          }
        }
        publishedDate
        title
      }
    }
    allContentfulPressPage {
      nodes {
        title
        inquiryWidget {
          contactName
          email
          inquiryTitle
          socialMedia {
            icon {
              description
              file {
                url
              }
            }
            url
          }
          socialTitle
        }
        seo {
          title
          description {
            description
          }
          socialImage {
            description
            file {
              url
            }
          }
        }
      }
    }
  }
`
