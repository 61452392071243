const monthName = monthNum =>
  ({
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  }[monthNum])

const formattedDate = (dateInput, press = false) => {
  const addDays = (currentDate, days) => {
    var date = new Date(currentDate.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }
  const dateObj = new Date(dateInput)

  const correctDate = addDays(dateObj, 1)

  return `${monthName(
    correctDate.getMonth()
  )} ${correctDate.getDate()}, ${correctDate.getFullYear()}`
}

export default formattedDate
