import React from "react"
import PressItem from "./pressItem.js"
import "../styles/components/_pressList.scss"

const PressList = ({ pressItems }) => {
  return (
    <ul id="press-list" className="press-list">
      {pressItems.map((pressItem, n) => {
        return <PressItem item={pressItem} num={n} key={n} />
      })}
    </ul>
  )
}

export default PressList
