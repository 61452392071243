import React from "react"
import formattedDate from "../functions/dateFormat.js"
import { Link } from "gatsby"
import "../styles/components/_pressItem.scss"

const PressItem = ({ item, num }) => {
  const itemClass = num < 5 ? "press-item active" : "press-item"

  return (
    <li className={itemClass}>
      <Link to={`/press-${item.publishedDate}`}>
        <ul className="press-text">
          <li className="brand">
            <img
              src={item.brand.brandLogo.file.url}
              alt={item.brand.brandLogo.description}
            />
          </li>
          <li className="title">{item.title}</li>
          <li className="published">{`${formattedDate(
            item.publishedDate,
            true
          )}`}</li>
        </ul>
      </Link>
    </li>
  )
}

export default PressItem
