const handleScroll = e => {
  const target = e.target
  const scrollPosition = target.scrollingElement.scrollTop
  const header = target.getElementById("header")
  if (scrollPosition > 20) {
    header.classList.add("shrunk")
  } else {
    header.classList.remove("shrunk")
  }
}

export default handleScroll
