import React from "react"
import "../styles/components/_listSelectors.scss"

const ListSelectors = ({ data, setCurrentSelector }) => {
  const listSelect = e => {
    const target = e.target

    if (target.classList.contains("recent-news")) {
      setCurrentSelector("news-results")
      sessionStorage.setItem("currentSelector", "news-results")
    } else {
      setCurrentSelector("press-results")
      sessionStorage.setItem("currentSelector", "press-results")
    }
  }

  return (
    <div className="list-selectors">
      {data.map((selector, n) => {
        return (
          <h2
            className={selector.toLowerCase().replace(" ", "-")}
            onClick={listSelect}
            key={n}
          >
            {selector}
          </h2>
        )
      })}
    </div>
  )
}

export default ListSelectors
