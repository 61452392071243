import React from "react"
import NewsItem from "./newsItem.js"
import "../styles/components/_newsList.scss"

const NewsList = ({ newsItems }) => {
  return (
    <ul id="news-list" className="news-list">
      {newsItems.map((newsItem, n) => {
        return <NewsItem item={newsItem} num={n} key={n} />
      })}
    </ul>
  )
}

export default NewsList
