import React from "react"
import "../styles/components/_inquiryWidget.scss"

const inquiryWidget = ({ title, contactName, email, socialTitle, social }) => {
  return (
    <div className="inquiry-widget">
      <div className="inquiry-section">
        <h3>{title}</h3>
        <div className="contact">
          <div className="contact-name">{contactName}</div>
          <a href={`mailto:${email}`} className="email">
            {email}
          </a>
        </div>
      </div>
      <div className="social-section">
        <h3>{socialTitle}</h3>
        <ul className="social-media">
          {social.map((account, n) => {
            return (
              <li key={n}>
                <a href={account.url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={account.icon.file.url}
                    alt={account.icon.description}
                  />
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default inquiryWidget
